
export type Collaborator = {
  email: string
  roles: OnStockPermission[]
}

import { CsSimpleDialog, CsBtn } from '@consteel/csuetify'
import Vue from 'vue'
import { OnStockPermission } from '@/modules/onstock'

export default Vue.extend({
  name: 'AddCollaboratorDialog',
  components: { CsSimpleDialog, CsBtn },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    addedCollaborators: {
      type: Array as () => Array<Collaborator>,
      default: [],
    },
  },
  data() {
    return {
      valid: false,
      collaboratorEmail: '',
      permission: '' as OnStockPermission | '',
      isAdmin: false,
      collaborators: [] as Array<Collaborator>,
    }
  },
  watch: {
    value(): void {
      this.resetData()

      if (this.value) {
        ;(this.$refs.addCollaboratorForm as HTMLFormElement)?.resetValidation()
      }
    },
  },
  computed: {
    permissions(): Array<{ value: OnStockPermission; text: string }> {
      return [
        { text: 'Tervező', value: OnStockPermission.Tervező },
        { text: 'Gyártó', value: OnStockPermission.Gyártó },
      ]
    },
    permissionRules() {
      return [
        (value) => {
          if (value) return true

          return this.$t('A jogosultság kötelező.')
        },
      ]
    },
    emailRules() {
      return [
        (value) => {
          if (!value) return this.$t('Az email kötelező.')

          if (this.collaborators.find((collaborator) => collaborator.email === value))
            return this.$t('Ez az együttműködő már szerepel a listában')

          if (this.addedCollaborators.find((collaborator) => collaborator.email === value))
            return this.$t('Ez az együttműködő már szerepel az adatbázisban')

          return true
        },
        (value) => {
          if (/.+@.+\..+/.test(value)) return true

          return this.$t('Email cím formátuma helytelen.')
        },
      ]
    },
  },
  methods: {
    resetData(): void {
      this.valid = false
      this.collaboratorEmail = ''
      this.permission = ''
      ;(this.isAdmin = false), (this.collaborators = [])
    },
    handleClickRemoveCollaborator(collaboratorToRemove: Collaborator): void {
      this.collaborators = this.collaborators.filter(
        (coll) => coll.email !== collaboratorToRemove.email
      )
    },
    handleAddButtonClick(): void {
      const isValid = (this.$refs.addCollaboratorForm as HTMLFormElement)?.validate()

      if (!isValid) return

      if (!!this.collaboratorEmail && !!this.permission) {
        this.collaborators = [
          ...this.collaborators,
          {
            email: this.collaboratorEmail,
            roles: [
              this.permission,
              ...(this.isAdmin ? [OnStockPermission.Admin as OnStockPermission] : []),
            ],
          },
        ]
        this.permission = ''
        this.isAdmin = false
        this.collaboratorEmail = ''
      }
    },
    async handleSendInvitiationClick(): Promise<void> {
      this.$emit('submit', this.collaborators)
    },
  },
})
