
import ExplorerHeader from '@/components/Explorer/ExplorerHeader.vue'
import { CsBtn, CsFlex, CsNavigationDrawer } from '@consteel/csuetify'
import Vue from 'vue'

export default Vue.extend({
  name: 'AccountView',
  components: {
    CsFlex,
    ExplorerHeader,
    CsBtn,
    CsNavigationDrawer,
  },
  computed: {
    currentPage(): string {
      return this.$route.path.replace('/account/', '')
    },
  },
  methods: {
    navigate(path: string) {
      if (this.currentPage === path) {
        return
      }
      this.$router.push(`/account/${path}`)
    },
  },
})
