var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    { staticClass: "explorer-header py-3 pl-3 pr-8" },
    [
      _c(
        "a",
        {
          on: {
            click: function ($event) {
              return _vm.$router.push("/explorer")
            },
          },
        },
        [_c("on-stock-logo-svg", { staticClass: "logo" })],
        1
      ),
      _c("change-theme-button"),
      _c("toolbar-menu"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }