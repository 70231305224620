var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "account-profile pa-5",
      attrs: { vertical: "", gap: "1rem" },
    },
    [
      _c(
        "cs-flex",
        {
          staticClass: "profile-data",
          attrs: { vertical: "", justify: "flex-start" },
        },
        [
          _c(
            "cs-flex",
            { attrs: { vertical: "" } },
            [
              _c(
                "cs-flex",
                {
                  staticClass: "py-5 px-11",
                  attrs: { "fill-width": "", justify: "stretch" },
                },
                [
                  _c("span", { staticClass: "profile-title" }, [
                    _vm._v(_vm._s(_vm.$t("Felhasználói menü/Együttműködők"))),
                  ]),
                  _c("cs-tooltip-avatar", {
                    staticClass: "cs-tooltip-avatar ml-auto",
                    attrs: {
                      email: _vm.currentUser?.email,
                      tooltipText: _vm.$t(
                        "Ez az avatar a megosztásnál jelenik meg másoknak"
                      ),
                    },
                  }),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "div",
                { staticClass: "px-11 py-7" },
                [
                  _vm.isAdmin
                    ? _c(
                        "cs-btn",
                        {
                          staticClass: "mr-auto my-5",
                          on: { click: _vm.onClickAddNewCollaborator },
                        },
                        [_vm._v(_vm._s(_vm.$t("Új együttműködő hozzáadása")))]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex justify-center flex-column",
                              attrs: { cols: "6" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "text-h6 d-flex-1 flex-1" },
                                [_vm._v(_vm._s(_vm.$t("Csapat")))]
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "hide-details": "",
                                  "append-icon": "mdi-magnify",
                                  "single-line": "",
                                  label: _vm.$t("Keresés..."),
                                  clearable: "",
                                  spellcheck: "false",
                                },
                                model: {
                                  value: _vm.search,
                                  callback: function ($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("v-data-table", {
                        staticClass: "elevation-1",
                        attrs: {
                          search: _vm.search,
                          headers: _vm.headers,
                          items: _vm.getCollaborators,
                          "fixed-header": "",
                          "items-per-page": -1,
                          height: "380px",
                          "footer-props": {
                            "items-per-page-all-text": _vm.$t("Összes"),
                            "items-per-page-text": _vm.$t(
                              "Sorok száma oldalanként"
                            ),
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: `role-results`,
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "text-left ma-8" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "Nincs találat. Ellenőrizd a helyesírást vagy próbálj rákeresni más kifejezésre."
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: `footer.page-text`,
                              fn: function ({
                                pageStart,
                                pageStop,
                                itemsLength,
                              }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(pageStart) +
                                      "-" +
                                      _vm._s(pageStop) +
                                      " / " +
                                      _vm._s(itemsLength) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: `no-data`,
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "empty-data" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "Még nincs felhasználó a listában."
                                        )
                                      ) + " "
                                    ),
                                    _c("br"),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: `item.avatar`,
                              fn: function ({ item: { email } }) {
                                return [
                                  _c("cs-avatar", { attrs: { email: email } }),
                                ]
                              },
                            },
                            {
                              key: `item.roles`,
                              fn: function ({ item: { roles } }) {
                                return _vm._l(roles, function (role, key) {
                                  return _c(
                                    "div",
                                    { key: key, staticClass: "role" },
                                    [_vm._v(" " + _vm._s(_vm.$t(role)) + " ")]
                                  )
                                })
                              },
                            },
                            {
                              key: `item.status`,
                              fn: function ({ item: { status } }) {
                                return [
                                  status === "ACCEPTED"
                                    ? _c(
                                        "v-chip",
                                        {
                                          attrs: { color: "green", small: "" },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Tag")))]
                                      )
                                    : _c(
                                        "v-chip",
                                        {
                                          attrs: { color: "orange", small: "" },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Függőben")))]
                                      ),
                                ]
                              },
                            },
                            {
                              key: `item.delete`,
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled: _vm.loading,
                                        icon: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.onClickDeleteCollaborator(
                                            item
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "grey darken-1" } },
                                        [_vm._v(" mdi-close ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _c("v-progress-linear", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        attrs: { indeterminate: "" },
                      }),
                    ],
                    1
                  ),
                  _c("cs-warning-dialog", {
                    attrs: {
                      doNotAskAgain: "",
                      twoButton: "",
                      submitText: _vm.$t("Törlés"),
                      width: "380",
                      header: "",
                      headerText: _vm.$t("Törlés"),
                      title: _vm.$t("Együttműködő törlése"),
                      description: _vm.$t(
                        "Biztos törlöd az együttműködőt a csapatból? A törlés visszafordíthatatlan és a megosztott projektek számára már nem lesznek elérhetők!"
                      ),
                    },
                    on: { submit: _vm.onClickApproveDeleteUser },
                    model: {
                      value: _vm.deleteUserDialog,
                      callback: function ($$v) {
                        _vm.deleteUserDialog = $$v
                      },
                      expression: "deleteUserDialog",
                    },
                  }),
                  _c("cs-warning-dialog", {
                    attrs: {
                      oneButton: "",
                      submitText: _vm.$t("Vissza"),
                      width: "380",
                      header: "",
                      headerText: _vm.$t("Törlés"),
                      title: _vm.$t("Együttműködő nem törölhető"),
                      description: _vm.$t(
                        "A kiválasztott együttműködő nem törölhető a csapatból, mivel saját lérehozott projekttel rendelkezik!"
                      ),
                    },
                    on: { submit: _vm.onClickApproveDeleteUser },
                    model: {
                      value: _vm.deleteProjectOwnerUserDialog,
                      callback: function ($$v) {
                        _vm.deleteProjectOwnerUserDialog = $$v
                      },
                      expression: "deleteProjectOwnerUserDialog",
                    },
                  }),
                  _c("add-collaborator-dialog", {
                    attrs: { addedCollaborators: _vm.getCollaborators },
                    on: { submit: _vm.addCollaborators },
                    model: {
                      value: _vm.addNewCollaboratorDialog,
                      callback: function ($$v) {
                        _vm.addNewCollaboratorDialog = $$v
                      },
                      expression: "addNewCollaboratorDialog",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }