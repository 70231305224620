
import LegalDialog from '@/components/Landing/LegalDialog.vue'
import { CsFlex, CsTooltipAvatar, CsTextField, CsWarningDialog } from '@consteel/csuetify'
import Vue from 'vue'
import { logout } from '@/modules/auth'
import { deleteOnStockUserRequest } from '@/modules/onstock/requests'
import { OnStockPermission, OnStockUser } from '@/modules/onstock'
import { authStore } from '@/modules/auth'

export default Vue.extend({
  name: 'AccountProfile',
  components: {
    CsFlex,
    CsWarningDialog,
    CsTextField,
    CsTooltipAvatar,
    LegalDialog,
  },
  data() {
    return {
      showLegalDialog: false,
      showDeleteProfileDialog: false,
    }
  },
  methods: {
    async deleteUser(): Promise<void> {
      if (this.currentUser) {
        await deleteOnStockUserRequest(this.currentUser.id)
      }
    },
    logout,
  },
  computed: {
    isAdmin(): boolean {
      return !!this.currentUser?.roles.includes(OnStockPermission.Admin)
    },
    currentUser(): OnStockUser | null {
      return authStore.currentOnStockUser
    },
    displayName(): string | undefined {
      return this.currentUser?.name
    },
    email(): string | undefined {
      return this.currentUser?.email
    },
    permission(): string | undefined {
      return this.currentUser?.roles?.join(', ')
    },
  },
})
