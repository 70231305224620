var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "account-profile pa-5",
      attrs: { vertical: "", gap: "1rem" },
    },
    [
      _c(
        "cs-flex",
        {
          staticClass: "profile-data",
          attrs: { vertical: "", justify: "flex-start" },
        },
        [
          _c(
            "cs-flex",
            { attrs: { vertical: "" } },
            [
              _c(
                "cs-flex",
                {
                  staticClass: "py-5 px-11",
                  attrs: { "fill-width": "", justify: "stretch" },
                },
                [
                  _c("span", { staticClass: "profile-title" }, [
                    _vm._v(_vm._s(_vm.$t("Felhasználói menü/Fiók"))),
                  ]),
                  !!_vm.currentUser?.email
                    ? _c("cs-tooltip-avatar", {
                        staticClass: "cs-tooltip-avatar ml-auto",
                        attrs: {
                          email: _vm.currentUser.email,
                          tooltipText: _vm.$t(
                            "Ez az avatar a megosztásnál jelenik meg másoknak"
                          ),
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "div",
                { staticClass: "px-11 py-7" },
                [
                  _c("div", { staticClass: "text-h6 pb-7" }, [
                    _vm._v(_vm._s(_vm.$t("Általános"))),
                  ]),
                  _c("div", { staticClass: "text-bold text-small mr-auto" }, [
                    _vm._v(_vm._s(_vm.$t("Teljes név"))),
                  ]),
                  _c("cs-text-field", {
                    attrs: {
                      disabled: "",
                      value: _vm.displayName,
                      variant: "underlined",
                    },
                  }),
                  _c("div", { staticClass: "text-bold text-small mr-auto" }, [
                    _vm._v(_vm._s(_vm.$t("Email"))),
                  ]),
                  _c("cs-text-field", {
                    attrs: {
                      disabled: "",
                      value: _vm.email,
                      variant: "underlined",
                    },
                  }),
                  _c("div", { staticClass: "text-bold text-small mr-auto" }, [
                    _vm._v(_vm._s(_vm.$t("Jogosultság"))),
                  ]),
                  _c("cs-text-field", {
                    attrs: {
                      disabled: "",
                      value: _vm.permission,
                      variant: "underlined",
                    },
                  }),
                  _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.isAdmin
                            ? _vm.$t(
                                "Adminisztrátori jogosultsággal projektműveleteket hajthatsz végre, megfeleltetést és jóváhagyást végezhetsz, véglegesítheted a projekteket, felvehetsz együttműködőket és megszabhatod az együttműködők jogosultságait."
                              )
                            : _vm.$t(
                                "Projektműveleteket hajthatsz végre és jóváhagyást végezhetsz a saját vagy veled megosztott projekteken."
                              )
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "cs-flex",
            {
              staticClass: "text-bold py-5 px-11",
              attrs: { vertical: "", gap: "1rem" },
            },
            [
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      _vm.showLegalDialog = true
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("Adatvédelem és felhasználási feltételek"))
                  ),
                ]
              ),
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      _vm.showDeleteProfileDialog = true
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Profil törlése")))]
              ),
              _c("a", { on: { click: _vm.logout } }, [
                _vm._v(_vm._s(_vm.$t("Kijelentkezés"))),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("legal-dialog", {
        model: {
          value: _vm.showLegalDialog,
          callback: function ($$v) {
            _vm.showLegalDialog = $$v
          },
          expression: "showLegalDialog",
        },
      }),
      _c("cs-warning-dialog", {
        attrs: {
          twoButton: "",
          submitText: "Törlés",
          description:
            " Biztos törlöd az OnStock profilod? A törlés visszafordíthatatlan és a projektek nem lesznek elérhetők!",
          title: _vm.$t("Profil törlése"),
        },
        on: { submit: _vm.deleteUser },
        model: {
          value: _vm.showDeleteProfileDialog,
          callback: function ($$v) {
            _vm.showDeleteProfileDialog = $$v
          },
          expression: "showDeleteProfileDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }