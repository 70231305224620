
import OnStockLogoSvg from '@/assets/OnStockLogo.svg.vue'
import ToolbarMenu from '@/components/ToolbarMenu.vue'
import ChangeThemeButton from '@/components/ChangeThemeButton.vue'
import { CsFlex } from '@consteel/csuetify'
import Vue from 'vue'

export default Vue.extend({
  name: 'ExplorerHeader',
  components: {
    CsFlex,
    ToolbarMenu,
    OnStockLogoSvg,
    ChangeThemeButton,
  },
})
